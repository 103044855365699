body { background-color: #ffffff; }
body { color: var(--clr-10737); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-10738);
font-family: 'aktiv-grotesk';
font-weight: 700;
font-style: normal;
line-height: 1;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-10738);
font-family: 'aktiv-grotesk';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-10738);
font-family: 'aktiv-grotesk';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-10738);
font-family: 'aktiv-grotesk';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-10738);
font-family: 'aktiv-grotesk';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: #000000;
font-family: 'aktiv-grotesk';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 18px;

}
.button {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-10737);
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 15;
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
summary {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
body {
font-family: 'aktiv-grotesk';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-10738);}
a:hover {color: var(--clr-10737);}
/* Secondary:2 */
.MES2 {
background-color: var(--clr-10737);
color: #ffffff;
 }
/* Secondary:3 */
.MES3 {
background-color: var(--clr-10737);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-10737);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Primary:4 */
.MES4 {
background-color: var(--clr-10738);
color: #ffffff;
 }
/* Primary:5 */
.MES5 {
background-color: var(--clr-10738);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-10738);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* News Right Menu Divider:6 */
.MES6 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-10739) transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC7{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 39px 20px;}

}
& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-10740-flat);}}
&.vertical .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-10740-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC7{background-color: var(--clr-10740); &:hover {background-color: 2;}
}
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: var(--clr-10737);
}
 &:hover > a.MEC7{color: 7;
}
 }

}
}
 }
/* Page Banner Panel:8 */
.MES8 {
background-color: var(--clr-10738);
& > .underlay, & > .inner-overlay { opacity: 0.1 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pgav.org.au/img/27276/164');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:100px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 200px;};
padding: 15px;

 }
.MES8 {
background-color: var(--clr-10738);
& > .underlay, & > .inner-overlay { opacity: 0.1 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pgav.org.au/img/27276/164');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:100px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 200px;};
padding: 15px;

h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
/* Home Block:9 */
.MES9 {
background-color: var(--clr-10738);
color: #ffffff;
font-size: 14.4px;
padding: 30px 20px;

 }
.MES9 {
background-color: var(--clr-10738);
color: #ffffff;
font-size: 14.4px;
padding: 30px 20px;

h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
h1.MEC9 { font-size: 19.75px; }
h1.MEC9 { @media #{$medium-up} { font-size: 32px; }; }
h2.MEC9 { font-size: 17.38px; }
h2.MEC9 { @media #{$medium-up} { font-size: 28px; }; }
h3.MEC9 { font-size: 15.8px; }
h3.MEC9 { @media #{$medium-up} { font-size: 24px; }; }
h4.MEC9 { font-size: 12.64px; }
h4.MEC9 { @media #{$medium-up} { font-size: 20px; }; }
h5.MEC9 { font-size: 13.43px; }
h5.MEC9 { @media #{$medium-up} { font-size: 16px; }; }
h6.MEC9 { font-size: 14.22px; }
h6.MEC9 { @media #{$medium-up} { font-size: 14.4px; }; }
 }
cite.MEC9{
color: #ffffff;
font-size: 14.4px;
}
/* Footer Panel:10 */
.MES10 {
background-color: var(--clr-10737);
color: #ffffff;
font-size: 12.6px;
padding: 30px 15px;

 }
.MES10 {
background-color: var(--clr-10737);
color: #ffffff;
font-size: 12.6px;
padding: 30px 15px;

h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: #ffffff;
 }
 }
a.MEC10 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC10{
color: #ffffff;
font-size: 12.6px;
}
/* Carousel Slider:11 */
.MES11 {
& .slider-arrow {color: #ffffff;
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slider-arrow:hover{color: var(--clr-10737);
} }
/* Home Section Panel:12 */
.MES12 {
padding: 40px 15px;

 }
.MES12 {
padding: 40px 15px;

 }
/* News Right Bar Panel:13 */
.MES13 {
background-color: var(--clr-10737);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-10737);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
a.MEC13 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC13{
color: #ffffff;
}
/* Home Slider:14 */
.MES14 {
& .slider-arrow {background-color: 19;
height: 100%;
color: transparent;
}& .slider-arrow:hover{background-color: 20;
color: transparent;
} }
/* Team Member Divider:15 */
.MES15 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-10738) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Staff Panel:17 */
.MES17 {
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
border-width: 1px;
border-style: solid;
border-color: var(--clr-10739);
 }
.MES17 {
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
border-width: 1px;
border-style: solid;
border-color: var(--clr-10739);
 }
cite.MEC17{
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Staff Button:18 */
.MES18 {
background-color: var(--clr-10738);
&:hover {background-color: var(--clr-10738);}
color: #ffffff;
&:hover { color: #ffffff;}
padding: 5px 30px;

 }
/* Dark:19 */
.MES19 {
background-color: transparent;
 }
/* Dark:20 */
.MES20 {
background-color: transparent;
 }
.MES20 {
background-color: transparent;
 }
/* Shade 1:21 */
.MES21 {
background-color: var(--clr-10741);
color: var(--clr-10737);
 }
/* Shade 1:22 */
.MES22 {
background-color: var(--clr-10741);
color: var(--clr-10737);
padding: 15px;

 }
.MES22 {
background-color: var(--clr-10741);
color: var(--clr-10737);
padding: 15px;

h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: var(--clr-10738);
 }
 }
cite.MEC22{
color: var(--clr-10737);
}
/* Border Panel:23 */
.MES23 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-10741);
 }
.MES23 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-10741);
 }
/* Back Button:24 */
.MES24 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Responsive Menu:25 */
nav.responsive-menu {
.menu-item.MEC25{background-color: var(--clr-10737);}
& .menu-item.MEC25, & .menu-item.MEC25 > div.menu-item-wrap{ & > a.MEC25, & > i{color: #ffffff;
text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC25 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 20px 5px 40px;}

@media #{$medium-up} {
& .menu-item > .menu-item-wrap {padding: 5px 100px;}

}
& .sub-menu{.menu-item.MEC25{background-color: 25;}
& .menu-item.MEC25, & .menu-item.MEC25 > div.menu-item-wrap{ & > a.MEC25, & > i{color: 2;
text-transform: uppercase;
text-align: left;
}
  }
 .sub-menu {}}

 }
/* Slider bottom panel:26 */
.MES26 {
background-color: var(--clr-10737);
color: #ffffff;
font-size: 14.4px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
padding: 14px 10px 10px 20px;

@media #{$large-up} {
border-width: 0 1px;
};
border-style: solid;
border-color: transparent transparent transparent var(--clr-10741);
 }
.MES26 {
background-color: var(--clr-10737);
color: #ffffff;
font-size: 14.4px;
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
padding: 14px 10px 10px 20px;

@media #{$large-up} {
border-width: 0 1px;
};
border-style: solid;
border-color: transparent transparent transparent var(--clr-10741);
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: #ffffff;
 }
h1.MEC26 { font-size: 15px; }
h1.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h2.MEC26 { font-size: 13.2px; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h3.MEC26 { font-size: 12px; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h4.MEC26 { font-size: 9.6px; }
h4.MEC26 { @media #{$medium-up} { font-size: 20px; }; }
h5.MEC26 { font-size: 10.2px; }
h5.MEC26 { @media #{$medium-up} { font-size: 16px; }; }
h6.MEC26 { font-size: 10.8px; }
h6.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
 }
cite.MEC26{
color: #ffffff;
font-size: 14.4px;
}
/* Alternate:27 */
.MES27 {
background-color: var(--clr-10742);
 }
/* Alternate:28 */
.MES28 {
background-color: var(--clr-10742);
color: #ffffff;
 }
.MES28 {
background-color: var(--clr-10742);
color: #ffffff;
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
cite.MEC28{
color: #ffffff;
}
/* Light:29 */
.MES29 {
background-color: var(--clr-10743);
 }
/* Light:30 */
.MES30 {
background-color: var(--clr-10741);
padding: 0 0 5px 0;

border-width: 2px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
 }
.MES30 {
background-color: var(--clr-10741);
padding: 0 0 5px 0;

border-width: 2px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
 }
/* Shade 2:31 */
.MES31 {
background-color: var(--clr-10739);
 }
/* Shade 2:32 */
.MES32 {
background-color: var(--clr-10739);
 }
.MES32 {
background-color: var(--clr-10739);
 }
/* Home Page Block:33 */
.MES33 {
background-color: var(--clr-10737);
&:hover, &.hover { background-color: var(--clr-10744);}
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pgav.org.au/img/27276/164');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
font-size: 14.4px;
padding: 30px 20px;

 }
.MES33 {
background-color: var(--clr-10737);
&:hover, &.hover { background-color: var(--clr-10744);}
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pgav.org.au/img/27276/164');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
font-size: 14.4px;
padding: 30px 20px;

h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
h1.MEC33 { font-size: 19.75px; }
h1.MEC33 { @media #{$medium-up} { font-size: 32px; }; }
h2.MEC33 { font-size: 17.38px; }
h2.MEC33 { @media #{$medium-up} { font-size: 28px; }; }
h3.MEC33 { font-size: 15.8px; }
h3.MEC33 { @media #{$medium-up} { font-size: 24px; }; }
h4.MEC33 { font-size: 12.64px; }
h4.MEC33 { @media #{$medium-up} { font-size: 20px; }; }
h5.MEC33 { font-size: 13.43px; }
h5.MEC33 { @media #{$medium-up} { font-size: 16px; }; }
h6.MEC33 { font-size: 14.22px; }
h6.MEC33 { @media #{$medium-up} { font-size: 14.4px; }; }
 }
cite.MEC33{
color: #ffffff;
font-size: 14.4px;
}
/* Gallery Accordion Style:34 */
details.MES34 {
& > summary { color: var(--clr-10738);
font-size:16px;
@media #{$medium-up} {
font-size:25px;
}
@media #{$large-up} {
font-size:20px;
}
 }
 }
/* News menu:35 */
.MES35 {
color: var(--clr-10737);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-10739);
 }
.MES35 {
color: var(--clr-10737);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-10739);
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: var(--clr-10737);
 }
 }
a.MEC35 { color: var(--clr-10737);
&:hover { color: var(--clr-10738);}
 }
cite.MEC35{
color: var(--clr-10737);
}
/* Main Menu RED:36 */
nav.me-Menu.MES36 {
& .menu-item.MEC36, & .menu-item.MEC36 > div.MEC36{ & > a.MEC36{color: var(--clr-10738);
font-size: 18px;
@media #{$large-up} {
font-size: 16.2px;
}text-transform: uppercase;
}
 &:hover > a.MEC36{color: var(--clr-10738);
}
 }
&.horizontal > .menu-item.MEC36 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC36 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC36 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC36 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 23px 5px 33px 5px;}

}
& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC36:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-10739-flat);}}
&.vertical .menu-item.MEC36:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-10739-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC36{background-color: var(--clr-10739); &:hover {background-color: 2;}
}
& .menu-item.MEC36, & .menu-item.MEC36 > div.MEC36{ & > a.MEC36{color: var(--clr-10738);
font-size: 15.3px;
}
 &:hover > a.MEC36{color: 7;
}
 }

}
}
 }
/* Footer Panel Red:37 */
.MES37 {
background-color: var(--clr-10738);
color: #ffffff;
font-size: 12.6px;
padding: 30px 15px;

 }
.MES37 {
background-color: var(--clr-10738);
color: #ffffff;
font-size: 12.6px;
padding: 30px 15px;

h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;
 }
 }
a.MEC37 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC37{
color: #ffffff;
font-size: 12.6px;
}
/* News Banner:38 */
.MES38 {
background-color: var(--clr-10739);
min-height:100px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 150px;};
padding: 15px;

 }
.MES38 {
background-color: var(--clr-10739);
min-height:100px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 150px;};
padding: 15px;

h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: var(--clr-10737);
 }
 }
a.MEC38 { color: var(--clr-10737);
&:hover { color: var(--clr-10737);}
 }
/* Share Button:39 */
.me-block.me-ShareButton .MES39{
figure.MEC39 { background-color: var(--clr-10737);
&:hover{background-color: var(--clr-10738);}
 }
figure.MEC39 { width: 28px;
height: 28px;

 }
figure.MEC39{ a { font-size: 14px;

color: #ffffff;
 }
&:hover a {color: #ffffff;}
 }

 }
/* Button outline:40 */
.MES40 {
&:hover {background-color: #ffffff;}
color: #ffffff;
&:hover { color: var(--clr-10737);}
padding: 5px 15px;

border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Gallery Accordion Style grey:41 */
details.MES41 {
& > summary{background-color: var(--clr-10739);
}
& > summary{padding: 15px;}

& > summary {& > div  > i{color: var(--clr-10738);
font-size: 14px;
}}
& > summary { color: var(--clr-10737);
font-size:16px;
@media #{$medium-up} {
font-size:25px;
}
text-transform: capitalize
 }
 }
/* Accordion:42 */
details.MES42 {
& > summary{background-color: var(--clr-10739);
}
color: var(--clr-10737);
& > article { color: var(--clr-10737);
 }
 }
/* News Back Button:43 */
.MES43 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-10738);
&:hover { color: var(--clr-10738);}
 }
/* Book Button:44 */
.MES44 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-10738);
&:hover { color: var(--clr-10742);}
border-radius: 3px;
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-10738);
&:hover { border-color: var(--clr-10742); }
 }
/* Red dots:45 */
.MES45 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-10738) transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* Event Date:46 */
.MES46 {
background-color: var(--clr-10739);
padding: 15px;

 }
.MES46 {
background-color: var(--clr-10739);
padding: 15px;

 }
/* Top Strip:47 */
.MES47 {
background-color: var(--clr-10738);
color: #ffffff;
padding: 5px 15px;

 }
.MES47 {
background-color: var(--clr-10738);
color: #ffffff;
padding: 5px 15px;

h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: #ffffff;
 }
 }
a.MEC47 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC47{
color: #ffffff;
}
/* Shopping Cart:48 */
.MES48 {
 & .cart-panel{  }
 color: var(--clr-10738);
font-size: 16.2px;
}/* Light-Smaller:49 */
.MES49 {
background-color: var(--clr-10741);
font-size: 14.4px;
padding: 8px 15px;

border-width: 2px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
 }
.MES49 {
background-color: var(--clr-10741);
font-size: 14.4px;
padding: 8px 15px;

border-width: 2px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
h1.MEC49 { font-size: 20px; }
h2.MEC49 { font-size: 17.6px; }
h3.MEC49 { font-size: 16px; }
h4.MEC49 { font-size: 12.8px; }
h5.MEC49 { font-size: 13.6px; }
h6.MEC49 { font-size: 14.4px; }
 }
cite.MEC49{
font-size: 14.4px;
}
/* White Text:50 */
.MES50 {
color: #ffffff;
 }
.MES50 {
color: #ffffff;
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: #ffffff;
 }
 }
cite.MEC50{
color: #ffffff;
}
/* News List:51 */
.MES51 {
font-size: 14.4px;
 }
.MES51 {
font-size: 14.4px;
 }
cite.MEC51{
font-size: 14.4px;
}
/* News Archive Menu:52 */
nav.me-Menu.MES52 {
& .menu-item.MEC52, & .menu-item.MEC52 > div.MEC52{ & > a.MEC52{color: var(--clr-10738);
text-transform: uppercase;
}
 &:hover > a.MEC52{color: var(--clr-10737);
}
 }
&.horizontal > .menu-item.MEC52 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC52 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC52 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC52 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 15px 0 0;}

& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC52.active { & > a{ color: var(--clr-10742);}
 }
&.horizontal .menu-item.MEC52:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-10739-flat);}}
&.vertical .menu-item.MEC52:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-10739-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC52{background-color: var(--clr-10739); &:hover {background-color: 2;}
}
& .menu-item.MEC52, & .menu-item.MEC52 > div.MEC52{ & > a.MEC52{color: var(--clr-10738);
font-size: 15.3px;
}
 &:hover > a.MEC52{color: 7;
}
 }

}
}
 }
/* Dark Page Banner:53 */
.MES53 {
background-color: var(--clr-10737);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pgav.org.au/img/27276/164');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:100px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 200px;};
padding: 30px 20px;

 }
.MES53 {
background-color: var(--clr-10737);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://pgav.org.au/img/27276/164');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:100px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 200px;};
padding: 30px 20px;

h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: #ffffff;
 }
 }
/* :54 */
.MES54 {
background-color: var(--clr-10745);
 }
/* Product Share:55 */
.me-block.me-ShareButton .MES55{
figure.MEC55 { width: 28px;
height: 28px;

 }
figure.MEC55{ a { font-size: 14px;

color: #ffffff;
 }
&:hover a {color: var(--clr-10738);}
 }

 }
/* VMAGA1:56 */
.MES56 {
background-color: var(--clr-10746);
color: #ffffff;
border-radius: 8px;
padding: 8px;

 }
/* VMAGA_2023_2:57 */
.MES57 {
background-color: var(--clr-10747);
color: #ffffff;
border-radius: 8px;
padding: 8px;

 }
/* VMAGA 2024:58 */
.MES58 {
background-color: #ff8800;
color: #000000;
 }
/* VMAGA 2024:59 */
.MES59 {
background-color: #ff8800;
color: #ffffff;
border-radius: 8px;
padding: 8px;

 }
/* 2024 VMAGA Pink Outline:60 */
.MES60 {
background-color: transparent;
color: var(--clr-10746);
padding: 8px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-10746);
 }
/* 2024 VMAGA Orange Outline:61 */
.MES61 {
background-color: transparent;
color: #ff8800;
padding: 8px;

border-width: 1px;
border-style: solid;
border-color: #ff8800;
 }
/* :62 */
.MES62 {
background-color: #ff880021;
 }
.MES62 {
background-color: #ff880021;
 }
